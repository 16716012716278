.App {
  text-align: center;
  font-family: "Poppins";
  font-weight: normal;
}

html {
  background-color: #1a1925;
  font-family: "Poppins";
}
.circletag {
  margin-bottom: 10px;
  display: block;
  width: 70px;
  height: 70px;
  background: #43424c;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.selected-circle {
  border: #ff2882 1px solid;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar:horizontal {
  height: 0px;
}

::-webkit-scrollbar-thumb {
}
.side_bar {
  transition: all 1s ease;
  transform: translate(-100%);
}
.show_side_bar {
  transform: translate(0);
}
