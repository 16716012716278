@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Medium.ttf) format("truetype");
  font-weight: medium;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: bold;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
